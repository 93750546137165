import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj29 = () => {
  return (
    <Layout>
      <SEO
        title="Six Degree of Separation"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-29">
          <div className="py-1-heading">
            <h1>Six Degree of Separation</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Six%20Degrees%20Of%20Separation"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Thing is, <br />
              There is maximum 6 people's distance from knowing any other
              person.
              <br />
              In simple words, you can meet your favourite people's easily
              <br />
              all you have to know about a connection of people's
              <br />
              <br />
              We are using a data file containg node connections .
              <br />
              <br />
              We will disclose, how much nodes we have to cross to reach another
              target node
              <br />
              Here nodes are peoples.
              <br />
              <br />
              Mark my words, you will not see any body far from 6 people.
              <br />
              That's it !!!
              <br />
              We all are really very near !!!
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>NetworkX</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Numpy</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj29
